<template>
  <v-card>
    <div v-if="!sameAddressView">
      <v-card-title class="justify-center text-center text-break">
        {{ i18n.t("selectFullAddress") }}
      </v-card-title>
      <v-card-text class="pt-5">
        <v-list dense two-line subheader outlined class="pb-0 rounded-lg">
          <v-list-item-group v-model="form.predictionsSelected" mandatory="mandatory" class="rounded-lg">
            <template v-for="(prediction, index) in form.predictions">
              <v-list-item v-bind:key="'L' + index" v-bind:value="prediction.description" class="rounded-lg">
                <template v-slot:default="{ active }">
                  <v-list-item-content
                    :class="index === Object.keys(form.predictions).length - 1 ? 'my-4 secondary--text' : ''">
                    <v-list-item-title
                      v-if="prediction.structured_formatting.main_text === i18n.t('continueWithThisAddress')"
                      class="justify-center text-center subtitle-2">
                      {{ prediction.structured_formatting.main_text }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text--primary justify-center text-center subtitle-2">
                      {{ prediction.structured_formatting.main_text !== i18n.t('continueWithThisAddress') ?
                        `${prediction.structured_formatting.main_text},`: ''}}
                      {{ prediction.structured_formatting.secondary_text }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon large v-if="!active">
                      mdi-checkbox-blank-outline
                    </v-icon>
                    <v-icon large v-else color="success">
                      mdi-checkbox-outline
                    </v-icon>
                  </v-list-item-action>
                </template>
              </v-list-item>
              <v-divider v-if="index < form.predictions.length - 1" v-bind:key="'D' + index"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-row class="no-gutters flex-wrap flex-row fill-height">
          <v-col cols="auto" class="grow pa-2">
            <BackBtn currentTabComponent="AddressView"></BackBtn>
          </v-col>
          <v-col cols="auto" class="grow pa-2">
            <ForwardBtn :parentRefs="$refs" currentTabComponent="AddressView"></ForwardBtn>
          </v-col>
        </v-row>
      </v-card-actions>
    </div>
    <!-- Continue With Same Address -->
    <div v-else-if="sameAddressView">
      <v-card-title class="justify-center text-center text-break">
        {{ i18n.t("predictions.subheader2") }}
      </v-card-title>
      <v-card-text class="pt-5">

        <v-form ref="addressForm" v-model="addressFormValid" lazy-validation>
          <!-- Street Input -->
          <v-card-text class="px-0 py-0 mb-1 text-label">{{ i18n.t("universalFormContent.fields.streetAddress") }}</v-card-text>
          <v-text-field flat dense class="rounded-lg input-label" name="street_address" v-bind:rules="formRules.streetRules" :placeholder="localizedLabels.streetAddressPlaceholder" v-bind:disabled="loading" v-model="form.street" solo outlined prepend-inner-icon="mdi-road-variant" color="grey"></v-text-field>

          <!-- City Input -->
          <v-card-text class="px-0 py-0 mb-1 text-label">{{ i18n.t("universalFormContent.fields.city") }}</v-card-text>
          <v-text-field flat dense class="rounded-lg input-label" name="city" v-bind:rules="formRules.cityRules" :placeholder="localizedLabels.cityPlaceholder" v-bind:disabled="loading" v-model="form.city" solo outlined prepend-inner-icon="mdi-city-variant" color="grey"></v-text-field>

          <!-- State Input -->
          <v-card-text class="px-0 py-0 mb-1 text-label">{{ localizedLabels.state }}</v-card-text>
          <v-text-field flat dense class="rounded-lg input-label" name="state" v-bind:rules="formRules.stateRules" :placeholder="localizedLabels.statePlaceholder" v-bind:disabled="loading" v-model="form.state" solo outlined prepend-inner-icon="mdi-map" color="grey"></v-text-field>

          <!-- Zip Code Input -->
          <v-card-text class="px-0 py-0 mb-1 text-label">{{ localizedLabels.zip }}</v-card-text>
          <v-text-field flat dense class="rounded-lg input-label" name="zip_code" v-bind:rules="formRules.zipRules" :placeholder="localizedLabels.zipPlaceholder" v-bind:disabled="loading" v-model="form.zip" solo outlined prepend-inner-icon="mdi-post-outline" color="grey"></v-text-field>

          <!-- Country Input -->
          <v-card-text class="px-0 py-0 mb-1 text-label">{{ i18n.t("universalFormContent.fields.country") }}</v-card-text>
          <v-text-field flat dense class="rounded-lg input-label" name="country" v-bind:rules="formRules.countryRules" :placeholder="localizedLabels.countryPlaceholder" v-bind:disabled="loading" v-model="form.country" solo outlined prepend-inner-icon="mdi-earth" color="grey"></v-text-field>
        </v-form>
        
      </v-card-text>
      <v-card-actions>
        <v-row class="no-gutters flex-wrap flex-row fill-height">
          <v-col cols="auto" class="grow pa-2">
            <BackBtn currentTabComponent="SameAddressView"></BackBtn>
          </v-col>
          <v-col cols="auto" class="grow pa-2">
            <ForwardBtn :parentRefs="$refs" currentTabComponent="SameAddressView"></ForwardBtn>
          </v-col>
        </v-row>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import i18n from "@/i18n";
import { useIndexStore } from '@/store/index';
import { useUserStore } from "@/store/user";
import { mapState, mapActions, mapWritableState } from 'pinia';

import BackBtn from "../requestquote/utility/backBtn.vue";
import ForwardBtn from "../requestquote/utility/forwardBtn.vue";

export default {
  name: "PredictionDialog",
  components: {"BackBtn": BackBtn, "ForwardBtn": ForwardBtn},
  props: ["country"],
  data() {
    return {
      i18n: i18n,
    };
  },
  computed: {
    ...mapWritableState(useUserStore, ["form"]),
    ...mapState(useIndexStore, []),
    ...mapWritableState(useIndexStore, ["loading", "dialog", "sameAddressView", "formRules", "addressFormValid"]),

    localizedLabels(){

      const labels = {
        'ca' : {
          'zip' : i18n.t("universalFormContent.fields.postal"),
          'state' : i18n.t("universalFormContent.fields.province"),
          'streetAddressPlaceholder' : i18n.t("predictions.placeholder.streetCA"),
          'cityPlaceholder' : i18n.t("predictions.placeholder.cityCA"),
          'statePlaceholder' : i18n.t("predictions.placeholder.stateCA"),
          'zipPlaceholder' : i18n.t("predictions.placeholder.zipCA"),
          'countryPlaceholder' : i18n.t("predictions.placeholder.countryCA"),
        },
        'us' : {
          'zip' : i18n.t("universalFormContent.fields.zip"),
          'state' : i18n.t("universalFormContent.fields.state"),
          'streetAddressPlaceholder' : i18n.t("predictions.placeholder.street"),
          'cityPlaceholder' : i18n.t("predictions.placeholder.city"),
          'statePlaceholder' : i18n.t("predictions.placeholder.state"),
          'zipPlaceholder' : i18n.t("predictions.placeholder.zip"),
          'countryPlaceholder' : i18n.t("predictions.placeholder.country"),
        }
      }

      if(this.country == 'canada'){
        return labels.ca;
      } else {
        return labels.us;
      }
    },

  },
  methods: {
    ...mapActions(useIndexStore, [

    ]),
  },
};
</script>

<style scoped>
.subtitle-2 {
  white-space: normal;
}
</style>