<!-- Referral Section -->
<!-- Optional Referral Expantion Panel Section For Request Quote Pages -->

<template>
    <v-expansion-panel>
        <v-expansion-panel-header color="rgb(240, 247, 254)" class="rounded-lg box-shadow-custom">
            <template>
            <v-row no-gutters align="center">
                <v-icon color="secondary" left>mdi-handshake-outline</v-icon>
                <span class="text-subtitle-2 secondary--text">{{i18n.t("referral")}}</span>
            </v-row>
            </template>
            <template v-slot:actions>
            <v-icon color="secondary">
                $expand
            </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-row>
            <v-col class="mt-6 pb-0">
                <v-card-text class="px-0 py-0 mb-0 text-label">
                {{i18n.t("referralName")}}
                </v-card-text>
                <v-text-field flat dense name="referral" :placeholder="i18n.t('referralNamePlaceholder')" 
                v-bind:rules="formRules.referralNameRules" v-bind:disabled="loading" v-model="form.referralName" 
                class="rounded-lg input-label" solo outlined color="grey"></v-text-field>
            </v-col>
            </v-row>
            <v-row>
            <v-col class="my-0 pt-0">
                <v-card-text class="px-0 py-0 mb-0 text-label">
                {{i18n.t("referralPhone")}}
                </v-card-text>
                <v-text-field flat dense name="referral" :placeholder="i18n.t('referralPhonePlaceholder')" 
                v-bind:rules="formRules.referralPhoneRules" v-bind:disabled="loading" v-model="form.referralPhone" 
                class="rounded-lg input-label" solo outlined color="grey"></v-text-field>
            </v-col>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
    import i18n from "@/i18n";
    import { useUserStore } from "../../../store/user";
    import { useIndexStore } from '../../../store/index';
    import { mapState, mapWritableState } from 'pinia';
    export default {
        name: "ReferralSection",
        data() {
            return {
                i18n: i18n,
            };
        },
        computed: {
            // Map state properties as readonly computed properties
            ...mapState(useIndexStore, [
                "formRules",
            ]),
            ...mapWritableState(useIndexStore, [
                "loading",
            ]),
            ...mapWritableState(useUserStore, [
                "form",
            ]),
        },
        methods: {
        },
    };
</script>

<style scoped>
    .box-shadow-custom {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
    }
    .v-expansion-panel::before,
    ::v-deep .v-expansion-panel::before {
        box-shadow: none !important;
    }
    .v-expansion-panel-content__wrap,
    ::v-deep .v-expansion-panel-content__wrap {
        padding: initial;
    }
</style>