<template>
    <div>
        <v-row v-if="currentTabComponent!=='AddressView' && currentTabComponent!=='SameAddressView'">
            <v-col v-if="currentTabComponent==='ServiceView'">
                <v-btn
                    block large :color="serviceFormErrors||!formValid?'error':'success'" class="white--text rounded-lg"
                    :loading="loading" :disabled="!loading?false:true" @click="loading?null:validate()">
                    {{ i18n.t("seeAvailableServices") }}
                    <template v-slot:loader>
                        <span>{{ i18n.t("validate") }}</span>
                        <v-progress-circular class="ml-2" indeterminate="indeterminate" size="23"></v-progress-circular>
                    </template>
                </v-btn>
                <v-card-actions class="d-flex justify-center">
                    <v-icon class="text--disabled" left="left" dense="dense">mdi-lock-outline</v-icon>
                    <span class="text-caption text--disabled">{{ $t('universalFormContent.recaptcha') }} {{ i18n.t('andTheGoogle') }} <a target="_blank" href="https://policies.google.com/privacy">{{ i18n.t('universalFormContent.fields.termsAndConditions.part4') }}</a> {{ i18n.t('and') }} <a target="_blank" href="https://policies.google.com/terms">{{ i18n.t('termsOfService') }}</a> {{ i18n.t('apply') }}</span>
                </v-card-actions>
            </v-col>
            <v-col v-if="showGetAQuote && currentTabComponent!=='DetailView'" cols="12" :md="form.menuNavigationId && haveServicesForThisType() && isConstruction?6:12">
                <v-btn
                    block large :color="!formValid&&!form.menuNavigationId?'error':'secondary'" class="white--text rounded-lg"
                    :loading="loading" :disabled="!loading&&!loadingWithService?false:true" @click="loading?null:validateService()">
                    <icon class="mr-3" svg='buttonquote'></icon>
                    {{ i18n.t("getAQuote") }}
                    <template v-slot:loader>
                        <span>{{ i18n.t("validate") }}</span>
                        <v-progress-circular class="ml-2" indeterminate="indeterminate" size="23"></v-progress-circular>
                    </template>
                </v-btn>
            </v-col>
            <v-col v-if="showGoToStore && currentTabComponent!=='DetailView'" cols="12" :md="form.menuNavigationId && haveServicesForThisType() && isConstruction?6:12">
                <v-btn
                    block large :color="!formValid&&!form.menuNavigationId?'error':'success'" class="white--text rounded-lg"
                    :loading="loadingWithService" :disabled="!loading&&!loadingWithService?false:true" @click="loadingWithService?null:validateWithService('Service Stage')">
                    <icon class="mr-3" svg='shoppingcart'></icon>
                    {{ i18n.t("goToStore") }}
                    <template v-slot:loader>
                        <span>{{ i18n.t("validate") }}</span>
                        <v-progress-circular class="ml-2" indeterminate="indeterminate" size="23"></v-progress-circular>
                    </template>
                </v-btn>
            </v-col>
            <v-col v-if="currentTabComponent==='DetailView'" cols="12" :md="apartmentsCondosHoas?6:12">
                <v-btn
                    block large :color="!formValid&&!form.menuNavigationId?'error':'success'" class="white--text rounded-lg"
                    :loading="loading" :disabled="!loading?false:true" @click="loading?null:validateDetail()">
                    <icon v-if="apartmentsCondosHoas" class="mr-3" svg='buttonquote'></icon>
                    {{ i18n.t("getMyQuote") }}
                    <template v-slot:loader>
                        <span>{{ i18n.t("validate") }}</span>
                        <v-progress-circular class="ml-2" indeterminate="indeterminate" size="23"></v-progress-circular>
                    </template>
                </v-btn>
            </v-col>
            <v-col v-if="currentTabComponent==='DetailView' && apartmentsCondosHoas" cols="12" md="6">
                <v-btn
                    block large :color="!formValid&&!form.menuNavigationId?'error':'secondary'" class="white--text rounded-lg"
                    :loading="loadingWithService" :disabled="!loading&&!loadingWithService?false:true" @click="loadingWithService?null:validateWithService('Service Stage')">
                    <icon class="mr-3" svg='shoppingcart'></icon>
                    {{ i18n.t("goToStore") }}
                    <template v-slot:loader>
                        <span>{{ i18n.t("validate") }}</span>
                        <v-progress-circular class="ml-2" indeterminate="indeterminate" size="23"></v-progress-circular>
                    </template>
                </v-btn>
            </v-col>
        </v-row>
        <v-btn v-if="currentTabComponent==='AddressView' || currentTabComponent==='SameAddressView'"
            block large :color="!addressFormValid&&currentTabComponent==='SameAddressView'?'error':'success'" class="white--text rounded-lg"
            :loading="loading" :disabled="!loading?false:true" @click="loading?null:addressContinue()">
            <v-spacer></v-spacer>
            {{ i18n.t("continue") }}
            <v-spacer></v-spacer>
            <v-icon x-large right>
                mdi-chevron-right
            </v-icon>
            <template v-slot:loader>
                <span>{{ i18n.t("validate") }}</span>
                <v-progress-circular class="ml-2" indeterminate="indeterminate" size="23"></v-progress-circular>
            </template>
        </v-btn>
    </div>
</template>

<script>
import { useUserStore } from "../../../store/user";
import i18n from "@/i18n";
import { useIndexStore } from '../../../store/index';
import { mapState, mapActions, mapWritableState } from 'pinia';
import icon from "../utility/SvgLoader";
import storefront from "@/plugins/storefront"
import googleApi from "@/plugins/google"

export default {
    name: "ForwardBtn",
    components:{icon},
    props: ["parentRefs","currentTabComponent"],
    data() {
        return {
            i18n: i18n,
        };
    },
    computed: {
        ...mapWritableState(useUserStore, ["form"]),
        ...mapState(useIndexStore, ["updateStages","country","isConstruction","showGetAQuote","showGoToStore","apartmentsCondosHoas"]),
        ...mapWritableState(useIndexStore, ["formValid","loading","dialog","loadingWithService","serviceFormErrors","updateStages","google","apiResult","sameAddressView", "addressFormValid"]),
    },
    methods: {
        ...mapActions(useIndexStore, [
        'haveServicesForThisType',
        'getAddressPrediction',
        'getLeadTypeFromServiceType',
        ]),
        validate() {
            var vm = this;
            if (vm.validateFormAndService()) {
                vm.updateStages("Address Stage");
            } else {
                vm.loading = false;
            }
        },
        validateFormAndService() {
            if(!this.form.serviceType){
                this.serviceFormErrors = true;
                return this.parentRefs.wasteForm.validate() && this.form.serviceType ;
            }
            else{
                this.serviceFormErrors = false;
            }
            return this.parentRefs.wasteForm.validate();
        },
        validateService() {
            this.loading = true;
            var vm = this;
            const leadType = vm.getLeadTypeFromServiceType(vm.form.serviceType,vm.form.categoryType);
            if (vm.validateForm()) {
                googleApi.gTagSendEvents(leadType.toLowerCase(),0,"Display Lead Field",process.env.VUE_APP_GTAG_LEAD_CATEGORY,vm.getLeadTypeFromServiceType(vm.form.serviceType,vm.form.categoryType),vm.form.categoryType,vm.apiResult.districtID);
                vm.updateStages("Service Stage");
            } else {
                vm.loading = false;
            }
        },
        validateForm() {
            if (!this.form.menuNavigationId) {
                this.formValid=false
                this.formErrors = [];
                this.formErrors.push(i18n.t("missingField"));
                return this.parentRefs.wasteForm.validate() && !this.formErrors.length;
            }
            return this.parentRefs.wasteForm.validate();
        },
        validateWithService(stage) {
            this.loading = true;
            this.loadingWithService = true;
            var vm = this
            if (vm.parentRefs.wasteForm.validate()) {
                vm.form.webLeadStep = stage;
                storefront.sendInfoWithService();
            }else{
                this.loading = false;
                vm.loadingWithService = false;
            }
        },
        validateDetail() {
            this.loading = true;
            var vm = this;
            if (vm.parentRefs.wasteForm.validate()) {
                vm.updateStages("Details Stage");
            } else {
                vm.loading = false;
            }
        },
        addressContinue() {
            var vm = this;
            if(vm.currentTabComponent==='AddressView'){
                vm.validateAddress();
            } else {
                if(vm.parentRefs.addressForm.validate()){
                    vm.getAddressPrediction(vm.google,vm.form.predictionsSelected);
                    vm.dialog = false;
                    vm.sameAddressView = false;
                }
            }
        },
        validateAddress() {
            var vm = this;
            if (vm.form.predictionsSelected != "other") {
                vm.getAddressPrediction(vm.google,vm.form.predictionsSelected);
                vm.dialog = false;
            } else {
                vm.sameAddressView = true;
                vm.form.street = vm.form.address;
            }
        },
    },
};
</script>