<template>
  <div data-vuetify>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link rel="preconnect" href="https://cdn.jsdelivr.net">
    <link href="https://fonts.googleapis.com/css2?family=Roboto+Flex:ital,wght@0,300;0,400;0,500;0,700;1,400&display=swap" rel="stylesheet">
    <link href="https://cdn.jsdelivr.net/npm/@mdi/font@7.x/css/materialdesignicons.min.css" rel="stylesheet">
    <v-app id="scrollToEmbed" class="rounded transparent">
      <v-main>
        <ContactWidgetV3
        v-if="componentWidget==='contact'"
        :canada="canada || false"
        :service="service || servicefromquote || ''"
        :services="services || ''"
        :light="light || false"
        :assigntolocation="assigntolocation || 0"
        :bulkitempickup="bulkitempickup || ''"
        :stopservice="stopservice || false"
        :seasonvacationphone="seasonvacationphone || false"
        :recycleplus=" recycleplus || false"
        :requestpickup="requestpickup || false"
        :phone="phone || '844-708-7274'"
        :lang="lang || 'en'"
        :terms="terms||(canada&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/conditions/':(canada&&lang==='en')||(canada&&!lang)?'https://www.wasteconnectionscanada.com/terms-of-use/':'https://www.wasteconnections.com/terms-of-use/')"
        :privacy="privacy||(canada&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/confidentialite/':(canada&&lang==='en')||(canada&&!lang)?'https://www.wasteconnectionscanada.com/privacy-policy/':'https://www.wasteconnections.com/privacy-policy/')"
        :map="map||location||(canada&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/emplacements/':(canada&&lang==='en')||(canada&&!lang)?'https://www.wasteconnectionscanada.com/locations/':'https://www.wasteconnections.com/locations/')"
        :pay="pay||(canada&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/paiement-de-factures-en-ligne/':(canada&&lang==='en')||(canada&&!lang)?'https://www.wasteconnectionscanada.com/bill-pay/#7000':'https://www.wasteconnections.com/bill-pay/#1010')"
        :name="name||'Waste Connections'"
        :pickupscheduleurl="pickupscheduleurl||(canada&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/horaire-des-collectes/':(canada&&lang==='en')||(canada&&!lang)?'https://www.wasteconnectionscanada.com/holiday-schedule-2/#7000':'https://www.wasteconnections.com/pickup-schedule/')"
        :faq="faq||(canada&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/faq/':(canada&&lang==='en')||(canada&&!lang)?'https://www.wasteconnectionscanada.com/faq/':'https://www.wasteconnections.com/faq/')"
        :hideTocOptions="hideTocOptions || ''"
        />
        <QuoteWidget
        v-if="componentWidget==='quote'"
        :service="service ||''"
        :servicesofferedResi="servicesofferedResi || ''"
        :servicesofferedComm="servicesofferedComm || ''"
        :assigntodistrict="assigntodistrict||0"
        :storefrontDistrictCodeOverride="storefrontDistrictCodeOverride||0"
        :lang="lang || 'en'"
        :primary="primary || ''"
        :secondary="secondary || ''"
        :phone="phone||'844-708-7274'"
        :country="country||''"
        :referral="referral || false"
        :terms="terms||(country==='canada'&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/conditions/':(country==='canada'&&lang==='en')||(country==='canada'&&!lang)?'https://www.wasteconnectionscanada.com/terms-of-use/':'https://www.wasteconnections.com/terms-of-use/')"
        :privacy="privacy||(country==='canada'&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/confidentialite/':(country==='canada'&&lang==='en')||(country==='canada'&&!lang)?'https://www.wasteconnectionscanada.com/privacy-policy/':'https://www.wasteconnections.com/privacy-policy/')"
        :name="name||'Waste Connections'"
        :splitter="splitter||false"
        :header="header || $t('chooseYourWasteSolution')"
        :newstoreapi="false"
        :serviceswantedwithpricing="serviceswantedwithpricing || true"
        :footerstore="footerstore || false"
        :headerstore="headerstore || false"
        :borderstore="borderstore || false"
        :serviceLobLocked="serviceLobLocked || false"
        :resiServicesSizes="resiServicesSizes || ''"
        :commServicesSizes="commServicesSizes || ''"
        :constServicesSizes="constServicesSizes || ''"
        :resiServicesFrequency="resiServicesFrequency || ''"
        :commServicesFrequency="commServicesFrequency || ''"
        :resiServicesShowRecycling="resiServicesShowRecycling || false"
        :commServicesShowRecycling="commServicesShowRecycling || false" />
        <CommercialWidget
        v-if="componentWidget==='commercial'"
        :service="service||''"
        :assigntodistrict="assigntodistrict||0"
        :bulkitempickup="bulkitempickup || ''"
        :lang="lang || 'en'"
        :primary="primary || ''"
        :secondary="secondary || ''"
        :phone="phone||'844-708-7274'"
        :country="country||''"
        :terms="terms||(country==='canada'&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/conditions/':(country==='canada'&&lang==='en')||(country==='canada'&&!lang)?'https://www.wasteconnectionscanada.com/terms-of-use/':'https://www.wasteconnections.com/terms-of-use/')"
        :privacy="privacy||(country==='canada'&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/confidentialite/':(country==='canada'&&lang==='en')||(country==='canada'&&!lang)?'https://www.wasteconnectionscanada.com/privacy-policy/':'https://www.wasteconnections.com/privacy-policy/')"
        :name="name||'Waste Connections'"
        :splitter="splitter||false"
        :header="header || $t('chooseYourWasteSolution')"
        :serviceswantedwithpricing="serviceswantedwithpricing || true"
        :commChartOfficeLabel="commChartOfficeLabel" />
        <RollOffWidget
        v-if="componentWidget==='rolloff'"
        :recommended="recommended || false"
        :newstoreapi="false"
        :canada="canada || false"
        :service="service || ''"
        :services="services || ''"
        :light="light || false"
        :assigntolocation="assigntolocation || 0"
        :assigntodistrict="assigntodistrict || 0"
        :storefrontDistrictCodeOverride="storefrontDistrictCodeOverride||0"
        :stopservice="stopservice || false"
        :phone="phone || '844-708-7274'"
        :lang="lang || 'en'"
        :terms="terms||(canada&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/conditions/':(canada&&lang==='en')||(canada&&!lang)?'https://www.wasteconnectionscanada.com/terms-of-use/':'https://www.wasteconnections.com/terms-of-use/')"
        :privacy="privacy||(canada&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/confidentialite/':(canada&&lang==='en')||(canada&&!lang)?'https://www.wasteconnectionscanada.com/privacy-policy/':'https://www.wasteconnections.com/privacy-policy/')"
        :map="map||location||(canada&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/emplacements/':(canada&&lang==='en')||(canada&&!lang)?'https://www.wasteconnectionscanada.com/locations/':'https://www.wasteconnections.com/locations/')"
        :pay="pay||(canada&&lang==='fr'?'https://www.wasteconnectionscanada.com/fr/paiement-de-factures-en-ligne/':(canada&&lang==='en')||(canada&&!lang)?'https://www.wasteconnectionscanada.com/bill-pay/#7000':'https://www.wasteconnections.com/bill-pay/#1010')"
        :name="name||'Waste Connections'"/>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import i18n from "@/i18n";
import ContactWidgetV3 from './components/contactus/MainPage';
import QuoteWidget from './components/requestquote/MainView';
import CommercialWidget from './components/dumpstercommercialchartquote/CommercialView';
import RollOffWidget from './components/rolloffdumpster/RollOffView';
import { mapWritableState, mapActions } from 'pinia';
import { useIndexStore } from './store/index';

export default {
  name: 'App',
  components: {
    QuoteWidget,
    CommercialWidget,
    ContactWidgetV3,
    RollOffWidget,
  },
  props: {
    component: {
      type: String,
      default: 'quote'
    },
    recycleplus: Boolean,
    requestpickup: Boolean,
    canada: Boolean,
    service: String,
    services: String,
    servicesofferedResi: String,
    servicesofferedComm: String,
    light: Boolean,
    assigntolocation: Number,
    assigntodistrict: Number,
    storefrontDistrictCodeOverride: Number,
    bulkitempickup: String,
    stopservice: Boolean,
    seasonvacationphone: Boolean,
    phone: String,
    lang: String,
    terms: String,
    privacy: String,
    map: String,
    location: String,
    pay: String,
    name: String,
    splitter: Boolean,
    country: String,
    referral: Boolean,
    header: String,
    primary: String,
    secondary: String,
    serviceswantedwithpricing: Boolean,
    commChartOfficeLabel: {
      type: Boolean,
      default: true
    },
    newstoreapi: Boolean,
    pickupscheduleurl: String,
    footerstore: Boolean,
    headerstore: Boolean,
    borderstore: Boolean,
    serviceLobLocked: Boolean,
    resiServicesSizes: String,
    commServicesSizes: String,
    constServicesSizes: String,
    resiServicesFrequency: String,
    commServicesFrequency: String,
    resiServicesShowRecycling: Boolean,
    commServicesShowRecycling: Boolean,
    recommended: Boolean,
    faq: String,
    hideTocOptions: String,
  },
  data: () => ({
    i18n: i18n,
  }),
  mounted: function () {
    window.addEventListener('pageshow', this.handlePageShow);
  },
  beforeDestroy() {
    window.removeEventListener('pageshow', this.handlePageShow);
  },
  created() {
    // Temporary part for GTranslate
    if (window.parent.location.href.includes('/fr')) {
      this.lang = 'fr'
    }

    // Assign language to i18n
    if(!this.lang){
      i18n.locale = 'en'
    } else {
      i18n.locale = this.lang;
    }

    // Dynamic SEO for title and H1 -> Now done in the CF Worker
    this.editSEO();
    // Changing to first page component automatically when changing the component
    if(this.component === 'contact'){
      this.currentTabComponent = "ContactWidgetV3"
      this.componentWidget = this.component;
      this.form.webSourceForm = "Contact Us From Global Component"
    }
    if(this.component === 'quote'){
      this.currentTabComponent = "ServiceView"
      this.componentWidget = this.component;
      this.form.webSourceForm = process.env.VUE_APP_GTAG_LEAD_CATEGORY
    }
    else if(this.component === 'commercial'){
      this.currentTabComponent = "ChooseDumpster"
      this.componentWidget = this.component;
      this.form.serviceType = "Commercial"
      this.form.webSourceForm = "Commercial Chart Embed - Generic"

    }
    else if(this.component === 'rolloff'){
      this.currentTabComponent = "ChooseRollOff"
      this.componentWidget = this.component;
      this.form.serviceType = "Roll Off"
      this.form.webSourceForm = "Roll Off Service Embed - Generic"
    }
  },
  computed: {
    ...mapWritableState(useIndexStore, {componentWidget:"component",form:"form",currentTabComponent:"currentTabComponent", servicefromquote:"servicefromquote"}),
  },
  methods: {
    ...mapActions(useIndexStore, ['resetAll','updateViewComponent','getIndexComponent']),
    editSEO() {
        // Get the reference to the current heading
        var title = document.title;
        var header1 = document.querySelectorAll('h1');
        // Get the param value in the url
        var qs = document.location.search.split(/[?&]/g);
        var kw = qs.reduce(function(acc, cur) {
          var temp = cur.split('=');
          return temp[0].indexOf('kw') > -1 ? temp[1] : acc;
        }, '');
        // Inject the new header1 right before the H1
        if (title && header1.length && kw && window.parent.location.hostname !== 'www.wasteconnections.com' && window.parent.location.hostname !== 'www.wasteconnectionscanada.com') {
            document.title = decodeURI(kw).charAt(0).toUpperCase() + decodeURI(kw).slice(1) + ' - ' + document.title;
            header1.forEach((header) => {
              if(header?.querySelector('span')?.innerText){
                header.querySelector('span').innerText = decodeURI(kw).charAt(0).toUpperCase() + decodeURI(kw).slice(1);
              }else{
                header.innerText = decodeURI(kw).charAt(0).toUpperCase() + decodeURI(kw).slice(1);
              }
            });
        }
    },
    handlePageShow(event) {
      if (event.persisted) {
        // The page was loaded from the BFCache
        this.updateViewComponent(this.getIndexComponent())
        this.resetAll();
        this.$forceUpdate(); // or other methods to reset the state
      }
    },
  },
};
</script>

<style scoped>
  [data-vuetify]{overflow-y:hidden!important}
  :global(.grecaptcha-badge){visibility:hidden}
  :global(.pac-item){font-family:Roboto,sans-serif !important;font-weight:400 !important;color:rgba(0,0,0,.87) !important;line-height:40px !important;font-size:16px;cursor:pointer}
  :global(.pac-item-query){font-family:Roboto,sans-serif !important;font-size:16px}
  :global(.pac-item:hover){background-color:rgb(246,246,246) !important;transition: .3s cubic-bezier(.25,.8,.5,1)}
  :global(.pac-container){color:rgba(0,0,0,.87)!important;max-height:166px;overflow-y:auto}
  :global(.pac-icon,.pac-icon-marker){width:0 !important;height:0 !important;background:none !important;background-image:none !important}
  ::v-deep .v-application, ::v-deep .v-application .text-subtitle-1, ::v-deep .v-application .text-caption, ::v-deep .v-application .text-h6 {font-family:'Roboto Flex', sans-serif !important;}
  ::v-deep div.v-application--wrap {min-height:100%!important}
  ::v-deep .container--fluid {padding:0px!important}
  ::v-deep .v-btn--is-elevated {box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.14)}
  :global(div>.rounded){border-radius:4px!important}
  :global(.transparent){background-color:transparent !important;border-color:transparent !important}
  :global(html){scroll-padding-top:150px}
</style>
