import { defineStore } from 'pinia'
import { useIndexStore } from './index';
import { useUserStore } from './user';

import { load } from "recaptcha-v3";
import reset from "@/plugins/reset"

import axios from "axios";

// import axios from "axios";
// import { load } from "recaptcha-v3";

import i18n from "@/i18n";

export const useRollOffStore = defineStore('RollOff', {
    state: () => ({
        serviceCardId:[],
        formValid:true,
        form: useUserStore().form,
        apiResult: useIndexStore().apiResult,
        // materialDisposeList:[
        //     {
        //         item: 'Household garbage',
        //     },
        //     {
        //         item: 'Construction debris',
        //     },
        //     {
        //         item: 'Concrete, bricks or rocks',
        //     },
            // {
            //     item: 'Recycle',
            //     key: '4'
            // },
            // {
            //     item: 'Comingle',
            //     key: '5'
            // },
            // {
            //     item: 'Cardboard',
            //     key: '6'
            // },
            // {
            //     item: 'Organics',
            //     key: '7'
            // },
            // {
            //     item: 'High Grade Paper',
            //     key: '8'
            // },
            // {
            //   item: 'Plastics (Including Shrink Wrap)',
            //   key: '9'
            // },
            // {
            //   item: 'Other',
            //   key: '10'
            // }
        // ],
        rollOffList:[
            {
                qty: 0 ,
                size:{
                    text: i18n.t('rollOffList.10YardDumpster', 'en'),
                    textFr: i18n.t('rollOffList.10YardDumpster', 'fr'),
                    binSize: "10 YARD",
                    spec: i18n.t('rollOffList.10YardDumpsterSpec', 'en'),
                    specFr: i18n.t('rollOffList.10YardDumpsterSpec', 'fr'),
                    img: "https://img.wasteconnections.com/corporate-office-the-woodlands/request_quote/construction-service/10_Yard_Person.png",
                    description: i18n.t('rollOffList.10YardDumpsterDesc', 'en'),
                    descriptionFr: i18n.t('rollOffList.10YardDumpsterDesc', 'fr'),
                    icon1: "house",
                    icon2: "axe",
                    icon3: "shovel",
                    icon4: "brickwall",
                    recommended: false,
                    available: false,
                },
                frequency: {},
            },
            {
                qty: 0 ,
                size:{
                    text: i18n.t('rollOffList.15YardDumpster', 'en'),
                    textFr: i18n.t('rollOffList.15YardDumpster', 'fr'),
                    binSize: "15 YARD",
                    spec: i18n.t('rollOffList.15YardDumpsterSpec', 'en'),
                    specFr: i18n.t('rollOffList.15YardDumpsterSpec', 'fr'),
                    img: "https://img.wasteconnections.com/corporate-office-the-woodlands/request_quote/construction-service/15_Yard_Person.png",
                    description: i18n.t('rollOffList.15YardDumpsterDesc', 'en'),
                    descriptionFr: i18n.t('rollOffList.15YardDumpsterDesc', 'fr'),
                    icon1: "couch",
                    icon2: "box",
                    icon3: "",
                    icon4: "",
                    recommended: false,
                    available: false,
                },
                frequency: {},
            },
            {
                qty: 0 ,
                size:{
                    text: i18n.t('rollOffList.20YardDumpster', 'en'),
                    textFr: i18n.t('rollOffList.20YardDumpster', 'fr'),
                    binSize: "20 YARD",
                    spec: i18n.t('rollOffList.20YardDumpsterSpec', 'en'),
                    specFr: i18n.t('rollOffList.20YardDumpsterSpec', 'fr'),
                    img: "https://img.wasteconnections.com/corporate-office-the-woodlands/request_quote/construction-service/20_Yard_Person.png",
                    description: i18n.t('rollOffList.20YardDumpsterDesc', 'en'),
                    descriptionFr: i18n.t('rollOffList.20YardDumpsterDesc', 'fr'),
                    icon1: "leaf",
                    icon2: "",
                    icon3: "wheelbarrow",
                    icon4: "cementmixer",
                    recommended: false,
                    available: false,
                },
                frequency: {},
            },
            {
                qty: 0 ,
                size:{
                    text: i18n.t('rollOffList.30YardDumpster', 'en'),
                    textFr: i18n.t('rollOffList.30YardDumpster', 'fr'),
                    binSize: "30 YARD",
                    spec: i18n.t('rollOffList.30YardDumpsterSpec', 'en'),
                    specFr: i18n.t('rollOffList.30YardDumpsterSpec', 'fr'),
                    img: "https://img.wasteconnections.com/corporate-office-the-woodlands/request_quote/construction-service/30_Yard_Person.png",
                    description: i18n.t('rollOffList.30YardDumpsterDesc', 'en'),
                    descriptionFr: i18n.t('rollOffList.30YardDumpsterDesc', 'fr'),
                    icon1: "gardenshovel",
                    icon2: "saw",
                    icon3: "paintroller",
                    icon4: "constructionhelmet",
                    recommended: false,
                    available: false,
                },
                frequency: {},
            },
            {
                qty: 0,
                size:{
                    text: i18n.t('rollOffList.40YardDumpster', 'en'),
                    textFr: i18n.t('rollOffList.40YardDumpster', 'fr'),
                    binSize: "40 YARD",
                    spec: i18n.t('rollOffList.40YardDumpsterSpec', 'en'),
                    specFr: i18n.t('rollOffList.40YardDumpsterSpec', 'fr'),
                    img: "https://img.wasteconnections.com/corporate-office-the-woodlands/request_quote/construction-service/40_Yard_Person.png",
                    description: i18n.t('rollOffList.40YardDumpsterDesc', 'en'),
                    descriptionFr: i18n.t('rollOffList.40YardDumpsterDesc', 'fr'),
                    icon1: "liftmachine",
                    icon2: "wheelloader",
                    icon3: "brickwall",
                    icon4: "",
                    recommended: false,
                    available: false,
                },
                frequency: {},
            },
        ]
    }),
    getters: {
    },
    actions: {

        // Function will check if address is verified
        // if not verified, it will trigger a reCaptcha and check the address for available services
        // and at the end, if everything has been verified, it will send data to salesforce
        sendHttpRequestRollOff(){

            useIndexStore().loading = true;

            // Trigger recaptcha and verify the address if it hasn't been verified
            if(!useIndexStore().addressVerified){
                load(process.env.VUE_APP_RECAPTCHA).then((recaptcha) => {
                    recaptcha.execute("validationQuote").then(function (token) {
                      axios
                        .post(
                          process.env.VUE_APP_RESTURL + "services/apexrest/ServiceProvidedSearch",
                          {
                            callType: "validation",
                            validationObject: { token: token },
                          }
                        )
                        .then(function (response) {
                          if (response.status === 200) {
                            var data = JSON.parse(response.data);
                            if (window.parent.google_tag_manager && typeof window.parent.dataLayer !== "undefined") {
                                window.parent.dataLayer = window.parent.dataLayer || [];
                                window.parent.dataLayer.push({
                                  event: 'eventTracking',
                                  action: 'Submit Address',
                                  event_category: 'Start Service',
                                  event_label: useIndexStore().getLeadTypeFromServiceType(useIndexStore().form.serviceType,useIndexStore().form.categoryType),
                                  value: 0,
                                  lead_type: useIndexStore().form.serviceType,
                                  lead_category: useIndexStore().form.categoryType,
                                  lead_district: useIndexStore().apiResult.districtID
                                });
                                if (data.success && data.action === "validationQuote" && data.score >= 0.3){

                                    useIndexStore().verifyGoogleAddress();
                                    useIndexStore().addressVerified = true;


                                }
                            } else {
                              if (data.success && data.action === "validationQuote" && data.score >= 0.3){

                                useIndexStore().verifyGoogleAddress();
                                useIndexStore().addressVerified = true;

                              }
                            }
                          }
                        })
                        .catch(function (error) {

                          useIndexStore().loading = false;

                          console.log(error);

                        });
                    });
                  });
            } else{

                useIndexStore().form.webLeadStep = 'Details Stage'
                useIndexStore().updateStages(useIndexStore().form.webLeadStep)

            }
        },

        // Filter and check the roll off list to see which one is available and recommended
        // depending on the address and the available services.
        disableRollOffList(){

                // compare size from backend and static data from frontend
                this.rollOffList?.some((item) => {
                    return this.getRollOffSizeWithServices().some((size) => {

                        // check if size match with title with size (it verify with their numbers)
                        if(item.size.text.toLowerCase().includes(size.data.packageContainerSize)){
                            item.size.available = true;
                        }
                        // Check in backend if the size is the recommended.
                        if(size.data.recommended === true && item.size.text.toLowerCase().includes(size.data.packageContainerSize)){
                            item.size.recommended = true;
                        }
                    });
                });
        },


        // Get list of Roll Off by filtering with the line of business
        getRollOffSizeWithServices(){

            if(this.apiResult.data){

                // get a list of all the packages that has the same name with the line of business
                return this.apiResult.data.ServicePackges.filter(o => o.LineOfBusiness===useIndexStore().getRealNameFromPackages(useIndexStore().getLeadTypeFromServiceType(this.form.serviceType,this.form.categoryType)));

            } else{

                return null;
            }
        },

        // Resets everything in the component
        resetRollOff(){
            reset.address();
            reset.user();
            reset.rolloff();
            reset.apiStoreData();
        },


    },

  })