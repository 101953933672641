/**
 * Sends an event to the Intellimize API.
 *
 * @param {string} eventName - Name of the event.
 * @param {object} [eventData={}] - Additional properties associated with the event.
 */
const sendIntellimizeEvent = (eventName, eventData = {}) => {
  if (window.parent.intellimize && typeof window.parent.intellimize.sendEvent === "function") {
    window.parent.intellimize.sendEvent(eventName, eventData);
  } else {
    console.error("Intellimize is not loaded or available.");
  }
};

export default {
  sendIntellimizeEvent,
};