<template>
  <div>
    <v-form ref="form" v-model="formValid" lazy-validation>
    <v-row>
      <v-col>
          <v-card-text primary-title class="px-0 mt-4 mb-0 title text-center font-weight-bold text-wrap">
              {{ i18n.t('chooseYourPreferredService') }}
          </v-card-text>
          <v-card-text class="text-center pt-0">
              <icon svg='step-marker-2'/>
          </v-card-text>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-0">
        <v-card-text class="pt-0 pl-1" >
          <v-btn width="96px" class="rounded-lg white--text mr-2 flex-grow-0 accent lighten-1" height="50px"  @click="loading ? null : back()">
          <v-icon large left>
            mdi-chevron-left
          </v-icon>
          <v-spacer></v-spacer>
          {{ i18n.t("back") }}
          <v-spacer></v-spacer>
          </v-btn>
        </v-card-text>
      </v-col>
      <!-- <v-col>
    <v-card class="rounded-lg py-2 mt-7" style="background-color: #1F528F;" min-width="335px">
      <v-row>
      <v-col class="pt-5">
      <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="card-label font-weight-bold white--text">{{form.title}}</v-list-item-title>
            <v-list-item-title class="card-label white--text" v-if="form.itemDescription">Holds: {{form.itemDescription}}</v-list-item-title>
          </v-list-item-content>
      </v-list-item>
      </v-col>
      <v-col class="garbage-number white--text pt-1">
        <icon svg="commercial-dumpster" style="fill: white;"/>
        <icon svg="garbage-bag" style="fill: white;"/>
        <span v-if="form.itemDescription !== ''">x 1{{form.wantedQty}}</span>
      </v-col>
        <icon svg="checkmark" class="checkmark-selected-form"/>
      </v-row>
    </v-card>
    </v-col> -->
    </v-row>
    <v-row>
      <v-col class="pt-0">
        <v-card-title primary-title class="mobile-my-20 pa-1 title-form">
          {{ i18n.t('addYourDetails') }}
        </v-card-title>
      </v-col>
    </v-row>
        <!-- Address Text Field -->
      <v-row>
        <v-col class="py-0">
          <v-card-text class="pa-1 mb-1 label-form">
            {{ i18n.t("address") }}
          </v-card-text>
          <v-text-field flat ref="autocompleteInput" name="Address" v-model="form.address" solo dense outlined v-bind:error-messages="completeAddressMsg" v-bind:rules="formRules.addressRules" class="input-label rounded-lg placeholder-position text--darken-2" :placeholder="i18n.t('addressPlaceholder')" v-bind:disabled="loading" prepend-inner-icon="mdi-map-marker"></v-text-field>
        </v-col>
      </v-row>
      <v-dialog transition="dialog-top-transition" v-model="dialog" max-width="900px" persistent class="pa-0" :disabled="loading" v-if="view.prediction" attach hide-overlay>
        <v-card>
          <v-card-title class="justify-center text-center text-break">
            {{ i18n.t("selectFullAddress") }}
          </v-card-title>
          <v-card-text>
            <v-list dense="dense" two-line="two-line" subheader="subheader">
              <v-list-item-group v-model="form.predictionsSelected" mandatory="mandatory" active-class="primary--text text--darken-2">
                <template v-for="(prediction, index) in form.predictions">
                  <v-list-item v-bind:key="'L' + index" v-bind:value="prediction.description">
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title class="justify-center text-center subtitle-1">
                          {{prediction.structured_formatting.main_text}}
                        </v-list-item-title>
                        <v-list-item-subtitle class="text--primary justify-center text-center subtitle-2">
                          {{prediction.structured_formatting.secondary_text}}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon large v-if="!active">
                          mdi-checkbox-blank-circle-outline
                        </v-icon>
                        <v-icon large v-else color="success">
                          mdi-check-circle-outline
                        </v-icon>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  <v-divider v-if="index < form.predictions.length - 1" v-bind:key="'D' + index" ></v-divider>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card-text>
          <v-card-actions class="d-flex flex-row">
            <v-btn width="39%" @click="dialog = false" height="70px" class="btn-label white--text rounded-lg grey darken-1">
              <v-icon x-large left>
                mdi-chevron-left
              </v-icon>
              <v-spacer></v-spacer>
              {{ i18n.t("back") }}
              <v-spacer></v-spacer>
            </v-btn>
            <v-btn width="60%" color="success" height="70px" class="btn-label rounded-lg white--text" v-ripple="loading ? false : true" @click="loading ? null : selectedPrediction(form.predictionsSelected)">
              <v-spacer></v-spacer>
                  {{loading ? i18n.t("validate") : i18n.t("continue")}}
              <v-spacer></v-spacer>
              <v-icon v-if="!loading" x-large right>
                mdi-chevron-right
              </v-icon>
              <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col class="py-0" :cols="windowSize.x < 650 ? 12 : 6">
          <v-card-text class="pa-1 mb-1 label-form">
            {{i18n.t("firstName")}}
          </v-card-text>
          <v-text-field flat dense :placeholder="i18n.t('firstNamePlaceholder')" v-bind:rules="formRules.firstNameRules"  v-bind:disabled="loading" v-model="form.firstName" name="First Name" class="rounded-lg input-label" solo outlined prepend-inner-icon="mdi-account"></v-text-field>
        </v-col>
        <v-col class="py-0" :cols="windowSize.x < 650 ? 12 : 6">
          <v-card-text class="pa-1 mb-1 label-form">{{ i18n.t("lastName") }}</v-card-text>
          <v-text-field flat dense class="rounded-lg input-label" :placeholder="i18n.t('lastNamePlaceholder')" v-bind:disabled="loading" v-model="form.lastName" name="Last Name" v-bind:rules="formRules.lastNameRules" solo outlined prepend-inner-icon="mdi-account"></v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col class="py-0">
          <v-card-text class="pa-1 mb-1 label-form">
            {{i18n.t("businessName")}}
          </v-card-text>
          <v-text-field dense flat :placeholder="i18n.t('businessNamePlaceholder')" v-bind:rules="formRules.businessNameRules" v-bind:disabled="loading" v-model="form.businessName" class="rounded-lg input-label" solo outlined prepend-inner-icon="mdi-store">
          </v-text-field>
        </v-col>
      </v-row> -->

      <!-- Email Text Field -->
      <v-row>
        <v-col  :cols="windowSize.x < 650 ? 12 : 6" class="my-0 py-0" >
          <v-slide-x-transition hide-on-leave class="slide-fade-enter-active">
            <v-card-text class="pa-1 mb-1 label-form d-flex inline-flex">
              {{ i18n.t("phone") }}
            </v-card-text>
          </v-slide-x-transition>
          <v-slide-x-transition hide-on-leave class="slide-fade-enter-active">
            <v-text-field flat v-model="form.phone" name="Phone" v-bind:rules="formRules.phoneOptionalRules" dense outlined class="input-label rounded-lg placeholder-position text--darken-2" solo @input="acceptNumber()" type="tel"  v-bind:disabled="loading" :placeholder="i18n.t('phonePlaceholder')" prepend-inner-icon="mdi-phone"></v-text-field>
          </v-slide-x-transition>
        </v-col>
        <v-col  :cols="windowSize.x < 650 ? 12 : 6" class="my-0 py-0">
          <v-slide-x-transition hide-on-leave class="slide-fade-enter-active">
          </v-slide-x-transition>
          <v-slide-x-transition hide-on-leave>
            <v-card-text class="pa-1 mb-1 label-form">
              {{ i18n.t("email") }}
            </v-card-text>
          </v-slide-x-transition>
          <v-slide-x-transition hide-on-leave class="slide-fade-enter-active">
            <v-text-field flat v-model="form.email" solo dense outlined type="email" v-bind:rules="formRules.emailRules" :placeholder="i18n.t('emailPlaceholder')" v-bind:disabled="loading" class="input-label rounded-lg placeholder-position text--darken-2" prepend-inner-icon="mdi-email"></v-text-field>
          </v-slide-x-transition>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0 pb-3">
          <v-card-text class="pa-1 mb-1 label-form">
            {{i18n.t("tellUsWhat")}}
          </v-card-text>
          <v-textarea flat v-model="form.description" v-bind:rules="formRules.descriptionRules" :hint="i18n.t('descriptionHint')" counter="counter" height="90px" :placeholder="i18n.t('descriptionPlaceholder')" solo outlined no-resize class="rounded-xl-description input-label" v-bind:disabled="loading"></v-textarea>
        </v-col>
      </v-row>
      <v-checkbox v-model="form.acceptConditions" v-bind:rules="formRules.acceptConditions" v-bind:disabled="loading" color="success darken-2" class="mt-4">
        <template v-slot:label>
          <div>
            {{ i18n.t("agreeTo") }}
            <span v-if="i18n.locale == 'en'" class="text-capitalize">{{ name }}</span>
            <v-tooltip attach bottom>
              <template v-slot:activator="{ on }">
                <a target="_blank" rel="nofollow noopener" v-bind:href="terms" v-on:click.stop="return false;" v-on="on">
                  {{ i18n.t("termsOfService") }}
                </a>
              </template>
              <span>
                {{ i18n.t("termsOfService") }}
              </span>
            </v-tooltip>
            {{ i18n.t("and") }}
            <v-tooltip attach bottom>
              <template v-slot:activator="{ on }">
                <a target="_blank" rel="nofollow noopener" v-bind:href="privacy" v-on:click.stop="return false;" v-on="on">
                  {{ i18n.t("privacyPolicy") }}
                </a>
              </template>
              <span>
                {{ i18n.t("privacyPolicy") }}
              </span>
            </v-tooltip>
          </div>
        </template>
      </v-checkbox>
      <v-row v-if="!formValid" class="my-6" >
        <div class="v-messages error--text px-6 text-center" role="alert">
          <div class="subtitle-2" >
            <v-icon class="error--text" left="left" dense="dense">mdi-alert-outline</v-icon> {{ i18n.t('missingField') }}
          </div>
        </div>
      </v-row>
        <div class="text-center mt-0 pt-0">
          <v-btn :color="!formValid ? 'error' : 'success'" block  height="44px" class="rounded-lg white--text" v-ripple="loading ? false : true" @click="loading ? null : validate()">
            <span class="btn-form">
              {{ loading ? i18n.t("validate") : i18n.t("getMyQuote") }}
            </span>
            <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
          </v-btn>
        </div>
    </v-form>
    <v-dialog v-model="recaptchaDialog" attach hide-overlay max-width="290">
        <v-card>
            <v-card-title class="text-h5 d-flex justify-center">
                <v-icon x-large color="primary">mdi-alert-circle-outline</v-icon>
            </v-card-title>
            <v-card-text class="text-center">{{ i18n.t('snackbar.body1') }}</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="recaptchaDialog = false">
                    {{ i18n.t('snackbar.close') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { useUserStore } from "../../store/user";
import { useIndexStore } from '../../store/index';
import { useDumpsterCommercialStore } from '../../store/dumpstercommercial';
import { mapState, mapWritableState } from 'pinia';
import { mapActions } from 'pinia';
import i18n from "@/i18n";
import axios from "axios";
import { load } from "recaptcha-v3";
import { Loader } from "@googlemaps/js-api-loader";
import icon from "../requestquote/utility/SvgLoader";

export default {
  name:"DumpsterCommercialForm",
  components:{icon},
  props: {
  service: String,
  assigntodistrict: Number,
  lang: String,
  phone: String,
  country: String,
  terms: String,
  privacy: String,
  name: String,
  splitter: Boolean,
  load: Boolean,
  valid: Boolean,

  header: String,
  primary: String,
  secondary: String,

  serviceswantedwithpricing: Boolean,

},

data() {
return {
    i18n: i18n,
    recaptchaDialog: false,
};
},
computed: {
// Map state properties as readonly computed properties
    ...mapWritableState(useUserStore, [
      "form",
      ]),
  ...mapState(useIndexStore, [
      'formRules',
      ]),
  ...mapWritableState(useIndexStore, [
    "form",
    "windowSize",
    "apiResult",
    "dialog",
    'formValid',
    "loading",
    'google',
    'completeAddress',
    'completeAddressMsg',
    'view',
    'serviceFormErrors',
  ]),
  ...mapWritableState(useDumpsterCommercialStore, [
      'dumpsters'
  ]),

},
  created() {
    if (!window.google) {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GMID,
        version: "weekly",
        libraries: ["places"],
        language: this.lang==='fr'?'fr':'en',
      });
      loader
        .load()
        .then((google) => {
          this.google = google;
          if (this.service) {
            this.initializeGoogleAutocomplete();
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.google = window.google;
    }
    i18n.locale = this.lang;
  },
  watch: {
    "form.address": function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.completeAddressMsg = "";
        this.$nextTick(function () {
            this.initializeGoogleAutocomplete();
        })
      }
    },
  },
  methods: {
    ...mapActions(useIndexStore, [
    'autocompleteSuggestions',
    'selectedPrediction',
    'getAddressComponents',
    'updateViewComponent',
    'acceptNumber',
  ]),
    ...mapActions(useDumpsterCommercialStore, [
    'classSvg',
    'fillSvg',
    'sendPriceInfoWithNoService',
    "back",
    ]),

      // function for google auto complete
    initializeGoogleAutocomplete() {
      var vm = this;
      const autocomplete = new vm.google.maps.places.Autocomplete(
        vm.$refs.autocompleteInput.$el.querySelector("input"),
        {
          componentRestrictions: { country: ["us", "ca"] },
          fields: ["address_components", "formatted_address"],
          types: ["address"],
        }
      );
      autocomplete.addListener("place_changed", function () {
        const place = autocomplete.getPlace();
        if (!place.address_components) {
          vm.completeAddressMsg = i18n.t("completeAddressRequired");
          return;
        } else {
          vm.form.address = place.formatted_address;
        }
      });
    },

  async validate() {
      var vm = this;
      if (this.$refs.form.validate()) {
        vm.loading = true;
        vm.form.webLeadStep = 'Details Stage';
        load(process.env.VUE_APP_RECAPTCHA).then((recaptcha) => {
          recaptcha.execute("validationQuote").then(function (token) {
            axios
              .post(
                process.env.VUE_APP_RESTURL + "services/apexrest/ServiceProvidedSearch",
                {
                  callType: "validation",
                  validationObject: { token: token },
                }
              )
              .then(function (response) {
                if (response.status === 200) {
                  var data = JSON.parse(response.data);
                  if (window.parent.google_tag_manager && typeof window.parent.dataLayer !== "undefined") {
                    window.parent.dataLayer = window.parent.dataLayer || [];
                    window.parent.dataLayer.push({
                      event: 'eventTracking',
                      action: 'Submit Address',
                      event_category: 'Start Service',
                      event_label: 'Commercial',
                      value: 0,
                      lead_type: vm.form.serviceType,
                      lead_category: vm.form.categoryType,
                      lead_district: vm.apiResult.districtID
                    });
                    if (data.success && data.action === "validationQuote" && data.score >= 0.3) {
                      vm.autocompleteSuggestions();
                    } else {
                      vm.recaptchaDialog = true;
                      vm.loading = false;
                    }
                  } else {
                    if (data.success && data.action === "validationQuote" && data.score >= 0.3) {
                      vm.autocompleteSuggestions()
                    } else {
                      vm.recaptchaDialog = true;
                      vm.loading = false;
                    }
                  }
                } else {
                  vm.loading = false;
                }
              })
              .catch(function (error) {
                vm.loading = false;
                console.log(error);
              });
          });
        });

    } else {
      vm.loading = false;
    }
    },
  }
}
</script>

<style scoped>

</style>