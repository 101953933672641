//  **********************************
//  Functions for Google API
//  **********************************

// initialize google api and check if address exist
const initializeGoogleAutocomplete = (google,input,callback) => {
      let inputAddress = '';
      if(google){
        const autocomplete = new google.maps.places.Autocomplete(
          input,
          {
            componentRestrictions: { country: ["us", "ca"] },
            fields: ["address_components", "formatted_address"],
            types: ["address"],
          }
        );
        autocomplete.addListener("place_changed", function () {
          if (!autocomplete.getPlace().address_components) {
            inputAddress=null;
          } else {
            inputAddress=autocomplete.getPlace().formatted_address;
          }
          callback(inputAddress);
        });
      }
};


// return promises response of status and google addresses
const autocompleteSuggestions = (google,address) => {
    const service = new google.maps.places.AutocompleteService();
    const sessionToken = new google.maps.places.AutocompleteSessionToken();

    return new Promise((resolve,reject) => {
      service.getPlacePredictions(
      {
        componentRestrictions: { country: ["us", "ca"] },
        types: ["address"],
        sessionToken: sessionToken,
        input: address,
      },
      function (predictions, status) {
        if (status == google.maps.places.PlacesServiceStatus.OK) {
          resolve({ predictions, status });
          return {predictions,status}
        } else {
          reject(status);
          return status
        }
      }
    );
  });
};


// Function when we select a Google Address (in the suggestions)
// and validate the Prediction exists
const selectedPrediction = (google, prediction) => {
      const geocoder = new google.maps.Geocoder();
      return new Promise((resolve,reject) => {
        if (prediction !== "other") {

        geocoder.geocode(
        {
          address: prediction,
        },
        function (res, status) {
          if (status == google.maps.places.PlacesServiceStatus.OK) {
            let streetNumber = getAddressComponents(res[0].address_components,"street_number","short_name");
            let route = getAddressComponents(res[0].address_components,"route","short_name");
            let street = streetNumber + " " + route;
            let city = getAddressComponents(res[0].address_components,"locality","short_name");
            let state = getAddressComponents(res[0].address_components,"administrative_area_level_1","short_name");
            let zip = getAddressComponents(res[0].address_components,"postal_code","short_name");
            let country = getAddressComponents(res[0].address_components,"country","long_name");
            let lat = res[0].geometry.location.lat();
            let lng = res[0].geometry.location.lng();
            let address = res[0].formatted_address;
            resolve({res,status,streetNumber,route,street,city,state,zip,country,lat,lng,address});
            return {res,status,streetNumber,route ,street,city,state,zip,country,lat,lng,address}
          } else {
            reject(status);
            return status
          }
        }
        )
      } else {
        resolve({prediction})
        return prediction
    }}
      );
};


const getAddressComponents = (components, type, name) => {
    let neighborhood = null;
    let administrative_area_level_5 = null;
    let administrative_area_level_4 = null;
    let administrative_area_level_3 = null;
    let administrative_area_level_2 = null;
    for (var key in components) {
      if (Object.prototype.hasOwnProperty.call(components, key)) {
        if (type === components[key].types[0]) {
          return name === "short_name" ? components[key].short_name : components[key].long_name;
        } else if (type === "locality" && "neighborhood" == components[key].types[0]) {
          neighborhood = name === "short_name" ? components[key].short_name : components[key].long_name;
        } else if (
          type === "locality" && "administrative_area_level_5" == components[key].types[0]
        ) {
          administrative_area_level_5 = name === "short_name" ? components[key].short_name : components[key].long_name;
        } else if ( type === "locality" && "administrative_area_level_4" == components[key].types[0] ) {
          administrative_area_level_4 = name === "short_name" ? components[key].short_name : components[key].long_name;
        } else if ( type === "locality" && "administrative_area_level_3" == components[key].types[0] ) {
          administrative_area_level_3 = name === "short_name" ? components[key].short_name : components[key].long_name;
        } else if ( type === "locality" && "administrative_area_level_2" == components[key].types[0] ) {
          administrative_area_level_2 = name === "short_name" ? components[key].short_name : components[key].long_name;
        }
      }
    }
    if (neighborhood != null) {
      return neighborhood;
    } else if (administrative_area_level_5 != null) {
      return administrative_area_level_5;
    } else if (administrative_area_level_4 != null) {
      return administrative_area_level_4;
    } else if (administrative_area_level_3 != null) {
      return administrative_area_level_3;
    } else if (administrative_area_level_2 != null) {
      return administrative_area_level_2;
    }
    return "";
};


// Fire event to Google tag analytic
const gTagSendEvents = async (label, value, action, category, leadType, leadCategory, district, email, phone) => {
    if (window.parent.google_tag_manager && typeof window.parent.dataLayer !== "undefined" && typeof action !== "undefined" && typeof category !== "undefined" && typeof label !== "undefined" && typeof value !== "undefined") {
        window.parent.dataLayer = window.parent.dataLayer || [];

        // Check if email and phone are defined before hashing
        const hashedEmail = email ? await hashData(email) : undefined;
        const normalizedPhone = phone ? normalizePhoneNumber(phone) : undefined;
        const hashedPhoneNumber = normalizedPhone ? await hashData(`+1${normalizedPhone}`) : undefined;

        window.parent.dataLayer.push({
          event: "eventTracking",
          action: action,
          event_category: category,
          event_label: label,
          value: value,
          lead_type: leadType,
          lead_category: leadCategory,
          lead_district: district,
          email: hashedEmail,
          phone_number: hashedPhoneNumber,
        });
    }
};

// Private function for Google tag analytic
const hashData = async (data) => {
  // Encode the data as a UTF-8 string
  const encoder = new TextEncoder();
  const dataBuffer = encoder.encode(data.trim().toLowerCase());

  // Hash the data using SHA-256
  const hashBuffer = await crypto.subtle.digest('SHA-256', dataBuffer);

  // Convert the hash to a hexadecimal string
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

  return hashHex;
};

const normalizePhoneNumber = (phoneNumber) => {
  return phoneNumber.replace(/[\s\-().]/g, '');
};


export default {initializeGoogleAutocomplete,autocompleteSuggestions,selectedPrediction,getAddressComponents,gTagSendEvents};