<template>
  <v-row>
    <!-- GOOGLE ADDRESS -->
    <v-col class="py-0">
      <v-card-text class="pa-0 mb-1 text-label">
        {{ i18n.t("address") }}
      </v-card-text>
      <v-text-field @keyup.enter="reCaptcha ? reCaptchaValidation('validationQuote') : null"
        @click:append="reset.address()" :height="height ? height : '38px'" :value="address" v-model="address"
        v-bind:error-messages="completeAddressMsg" flat ref="autocompleteInput" solo dense outlined
        :rules="rules ? rules : []" class="input-label rounded-lg placeholder-position text--darken-2" name="Address"
        :placeholder="country === 'canada' ? i18n.t('addressPlaceholderCanada') : i18n.t('addressPlaceholder')"
        :disabled="loading" prepend-inner-icon="mdi-map-marker" color="grey"></v-text-field>
    </v-col>

    <!-- DIALOG for prediction and more... -->
    <v-dialog transition="dialog-top-transition" v-model="dialog" max-width="600px" persistent :disabled="loading"
      attach hide-overlay>

      <!-- Prediction Dialog -->
      <Prediction v-if="view.prediction" :country="country"></Prediction>

      <!-- Hoa Dialog -->
      <Hoa v-if="view.hoa" :disabled="disabled"></Hoa>

      <!-- Tenant Dialog -->
      <Tenant v-if="view.tenant" :disabled="disabled"></Tenant>

      <!-- Hoa and Tenant Dialog -->
      <HoaAndTenant v-if="view.hoaAndTenant" :disabled="disabled"></HoaAndTenant>
    </v-dialog>
  </v-row>
</template>

<script>
import i18n from '@/i18n'
import { Loader } from '@googlemaps/js-api-loader'
import { mapActions, mapWritableState } from 'pinia';
import { useIndexStore } from '@/store/index';
import { useUserStore } from '@/store/user';

import reset from "../../plugins/reset"
import Prediction from "@/components/dialog/Prediction.vue";
import Hoa from "@/components/dialog/Hoa.vue";
import Tenant from "@/components/dialog/Tenant.vue";
import HoaAndTenant from "@/components/dialog/HoaAndTenant.vue";

export default {
  name: "GoogleAddress",
  components: { Prediction, Hoa, Tenant, HoaAndTenant },
  props: ["disabled", "value", 'height', 'width', "rules", "error-messages", "lang", "reCaptcha", "country"],
  data() {
    return {
      i18n: i18n,
      address: this.value,
      reset: reset,
    }
  },
  created() {
    if (!window.google) {
      const loader = new Loader({
        apiKey: process.env.VUE_APP_GMID,
        version: "weekly",
        libraries: ["places"],
        language: this.lang === 'fr' ? 'fr' : 'en',
      });
      loader
        .load()
        .then((google) => {
          this.google = google;
          this.initializeGoogleAutocomplete();
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      this.google = window.google;
    }
  },
  mounted: function () {
    if (window.google) {
      this.initializeGoogleAutocomplete();
    }
  },
  computed: {
    ...mapWritableState(useUserStore, ["form"]),
    ...mapWritableState(useIndexStore, ["loading", 'google', 'dialog', 'view', 'component', 'currentTabComponent', 'completeAddressMsg']),
  },
  watch: {
    // Update v-model for custom component
    value(newValue) {
      this.address = newValue;
      this.completeAddressMsg = "";
    },
    // Update value of variable address and
    // reset everything related to address
    "address": function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.address = newValue;
        this.completeAddressMsg = "";
        this.$emit('input', newValue);
      }
    },
  },

  methods: {
    ...mapActions(useIndexStore, ["reCaptchaValidation"]),
    initializeGoogleAutocomplete() {
      var vm = this;
      if (vm.google) {
        const observer = new MutationObserver(function (mutationsList, observer) {
          for (const mutation of mutationsList) {
            if (mutation.attributeName == 'autocomplete' && mutation.target.getAttribute('autocomplete') == 'off') {
              observer.disconnect();
              mutation.target.setAttribute('autocomplete', 'Address');
            }
          }
        });
        observer.observe(vm.$refs.autocompleteInput.$el.querySelector("input"), { attributes: true });
        const autocomplete = new vm.google.maps.places.Autocomplete(
          vm.$refs.autocompleteInput.$el.querySelector("input"),
          {
            componentRestrictions: { country: ["us", "ca"] },
            fields: ["address_components", "formatted_address"],
            types: ["address"],
          }
        );
        autocomplete.addListener("place_changed", function () {
          const place = autocomplete.getPlace();
          if (!place.address_components) {
            vm.completeAddressMsg = i18n.t("completeAddressRequired");
            return;
          } else {
            if (vm.reCaptcha) {
              vm.reCaptchaValidation('validationQuote')
            }
            vm.address = place.formatted_address;
            vm.completeAddressMsg = "";
          }
        });
      }
    },
  },
}
</script>

<style scoped>
::v-deep .placeholder-position input::placeholder {
  padding-left: 5px !important;
}

::v-deep .input-label {
  font-size: 14px !important;
  word-break: normal !important;
  color: black !important;
  border-radius: 10px !important;
}

::v-deep .v-input__icon.v-input__icon--prepend-inner i {
  font-size: 28px !important;
  color: #1f528f;
}
</style>